import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import './index.css';
import Flyer from './flyer/Flyer';
import FoundError from './flyer/FoundError';
import LearnMore from './flyer/LearnMore';
import Contact from './flyer/Contact';
import Playground from './playground/Playground';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ToastContainer } from 'react-toastify';

// Erstelle ein Thema mit den gewünschten Farben
const theme = createTheme({
  palette: {
    primary: {
      main: '#002935',
    },
    secondary: {
      main: '#cb425a',
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/flyer" element={<Flyer />} />
          <Route path="/flyer/found-error" element={<FoundError />} />
          <Route path="/flyer/learn-more" element={<LearnMore />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/demo" element={<Playground />} />
          <Route path="/" element={<App />} />
        </Routes>
      </Router>
    
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();