import React from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";

function ConfirmEndModal({ show, onHide, endPlayground, mode }) {
  let warntext = "";

  if (mode === "playground") {
    warntext = (
      <DialogContentText>
        Sind Sie sicher, dass Sie die Demo-Training beenden möchten? <br />
        <br />
        Es handelt sich nur um eine Demo, aber in einem tatsächlichen Training
        würde dies auch das Ende für Ihre Teammitglieder bedeuten.
      </DialogContentText>
    );
  } else {
    warntext = (
      <DialogContentText>
        Sind Sie sicher, dass Sie das Training beenden möchten? <br />
        <br />
        Dies kann nicht rückgängig gemacht werden und für deine Teammitglieder
        bedeutet dies auch das Ende.
      </DialogContentText>
    );
  }

  return (
    <Dialog open={show} onClose={onHide}>
      <DialogTitle>Demo Beenden</DialogTitle>
      <DialogContent>{warntext}</DialogContent>
      <DialogActions>
        <Button onClick={onHide} color="primary">
          Abbrechen
        </Button>
        <Button onClick={endPlayground} color="secondary">
          Beenden
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmEndModal;
