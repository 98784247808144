import React from "react";
import { Dialog, DialogContent } from "@mui/material";
import TeamTable from "./PlaygroundTeamTable";

function PlaygroundTeamModal({ show, onHide, team }) {
  return (
    <Dialog open={show} onClose={onHide} maxWidth="md" fullWidth>
      <DialogContent style={{ overflowX: "auto" }}>
        <TeamTable team={team} highlightUser={true} />
      </DialogContent>
    </Dialog>
  );
}

export default PlaygroundTeamModal;
