import React, { useState, useEffect } from 'react';
import { Container, Button, TextField, Checkbox, FormControlLabel, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { writeErrorFoundLead } from './firebase';
import Celebration from './Celebration';
import Confetti from 'react-confetti';
import logo from '../logo-simulern.png';

const handleBackClick = () => {
  window.location.href = '/flyer';
}

function FoundError() {
  const [showEmailField, setShowEmailField] = useState(false);
  const [errorName, setErrorName] = useState('');
  const [message, setMessage] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [confettiPieces, setConfettiPieces] = useState(200);
  const [dialogShown, setDialogShown] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setConfettiPieces(0), 5000); // Reduce confetti pieces to 0 over 5 seconds
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    setDialogShown(true); // Show dialog when component mounts
  }, []);

  const handleCheckboxChange = (event) => {
    setShowEmailField(event.target.checked);
    setIsChecked(event.target.checked);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleErrorNameChange = (event) => {
    setErrorName(event.target.value);
  };

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isSubmitDisabled = !errorName || !message || !isChecked || (showEmailField && !isEmailValid(email));

  const handleSubmission = (errorName, message, email) => {
    const errorFoundLead = {
      errorName,
      message,
      email,
      timestamp: new Date().toISOString()
    };
    writeErrorFoundLead(errorFoundLead).then(() => {
      setIsSubmitted(true);
    }).catch((error) => {
      alert(`Fehler beim Speichern der Nachricht: ${error}`);
    });
  };

  const handleClose = () => {
    setDialogShown(false);
  };

  if (isSubmitted) {
    return <Celebration />;
  }

  return (
    <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <Confetti numberOfPieces={confettiPieces} recycle={false} />
      <img src={logo} className="App-logo" alt="logo" height={50} style={{ position: 'absolute', top: '20px', right: '20px' }} />

      <Button variant="contained" onClick={handleBackClick} style={{ position: 'absolute', top: '20px', left: '20px', backgroundColor: "#002935" }}>
        Zurück
      </Button>

      <b style={{ marginTop: '100px', marginBottom: '20px', textAlign: 'center', fontSize: '18px' }}>
        Wie schön, dass
        du mitmachst! Wir sind gespannt zu lesen, welchen Fehler
        du gefunden hast!
      </b>

      <Dialog
        open={dialogShown}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Hinweis"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Einen möglichen Fehler gefunden? Melde uns diesen und lass uns über dein persönliches Wunsch-Szenario für eine virtuelle SimuLern-Session reden!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Weiter</Button>
        </DialogActions>
      </Dialog>

      <TextField
        label="Gib hier deinen Fehler ein"
        variant="outlined"
        fullWidth
        margin="normal"
        value={errorName}
        onChange={handleErrorNameChange}
        style={{ marginTop: '5px' }}
      />
      <TextField
        label="Warum ist der Fehler ein Sicherheitsrisiko?"
        variant="outlined"
        fullWidth
        margin="normal"
        multiline
        rows={4}
        value={message}
        onChange={handleMessageChange}
        style={{ marginTop: '5px' }}
      />
      <br />
      <FormControlLabel
        control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} />}
        label="Hiermit erkläre ich mich einverstanden, dass SimuLern mich bzgl. der Auflösung kontaktiert"
      />
      {showEmailField && (
        <TextField
          label="Gib hier deine E-Mail ein"
          variant="outlined"
          fullWidth
          margin="normal"
          value={email}
          onChange={handleEmailChange}
        />
      )}

      <Button variant="contained" color="primary" style={{ marginTop: '20px' }} disabled={isSubmitDisabled} onClick={() => handleSubmission(errorName, message, email)}>
        Abschicken
      </Button>
      <br />
      <br />
    </Container>
  );
}

export default FoundError;
