import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Dialog, DialogTitle, DialogContent, DialogActions, Accordion, AccordionSummary,
  AccordionDetails, Button, Typography
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import klinikum_stuttgart from "./klinikum_stuttgart.png";

function PatientFile(props) {


  return (
    <Dialog
      open={props.show}
      onClose={props.onHide}
      fullScreen
      aria-labelledby="patient-file-dialog-title"
    >
      <DialogTitle id="patient-file-dialog-title">
        <img
          src={klinikum_stuttgart}
          alt="Klinikum Stuttgart"
          width="60px"
          style={{ borderRadius: "7%" }}
        />
        &emsp;Patientenakte
      </DialogTitle>

      <DialogContent dividers>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Stammblatt</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              <li>
                <span style={{ fontWeight: "bold" }}>
                  Name, Vorname, Geburtsdatum:
                </span>{" "}
                Frau Grausam, Dolores, 24.12.1924
              </li>
              <li>
                <span style={{ fontWeight: "bold" }}>
                  Reanimationsstatus:
                </span>{" "}
                Ja
              </li>
              <li>
                <span style={{ fontWeight: "bold" }}>Hauptdiagnose:</span>{" "}
                Gonarthrose Kniegelenk rechts
              </li>
              <li>
                <span style={{ fontWeight: "bold" }}>Nebendiagnose:</span> St.
                Nach Hüfttotalprothese beidseits (links: 2003, rechts: 1996)
                Essentielle Hypertonie (2002) Morbus Crohn (2005)
              </li>
              <li>
                <span style={{ fontWeight: "bold" }}>Allergien:</span> Nüsse
              </li>
              <li>
                <span style={{ fontWeight: "bold" }}>Jetziges Leiden:</span>{" "}
                Frau Grausam tritt um 07.30 Uhr geplant in die Klinik ein, für
                eine Knietotalprothese rechts. Sie äussert Schmerzen im
                rechten Knie visuelle Analogskala (VAS) in Ruhe 1; in Bewegung
                3, vor allem beim Treppensteigen.
              </li>
              <li>
                <span style={{ fontWeight: "bold" }}>Diagnostik:</span> Labor
                unauffällig, EKG unauffälig
              </li>
              <li>
                <span style={{ fontWeight: "bold" }}>Soziales:</span> Frau
                Grausam wohnt zusammen mit ihrem Mann in einem Haus ohne
                Treppen. Sie wird von ihrem Mann und den drei Kindern gut
                unterstützt und äußert ein gutes soziales Umfeld zu haben.
                Frau G. ist selbstständig mobil, ohne Gehhilfen und macht
                einen gepflegten Eindruck.
              </li>
            </ul>

          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Medikamente</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHead>Medikamente fix:</TableHead>
                    <TableHead>morgens</TableHead>
                    <TableHead>mittags</TableHead>
                    <TableHead>abends</TableHead>
                    <TableHead>nachts</TableHead>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Eprosartan Mepha Lactabs 600 mg</TableCell>
                    <TableCell>0.5</TableCell>
                    <TableCell>0</TableCell>
                    <TableCell>0</TableCell>
                    <TableCell>0</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Esomprazol Tbl. 20 mg</TableCell>
                    <TableCell>1</TableCell>
                    <TableCell>0</TableCell>
                    <TableCell>0</TableCell>
                    <TableCell>0</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Metamizol Tbl. 500 mg</TableCell>
                    <TableCell>2</TableCell>
                    <TableCell>2</TableCell>
                    <TableCell>2</TableCell>
                    <TableCell>2</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Oxycodon/Naloxon Tbl. 10 mg/5 mg</TableCell>
                    <TableCell>1</TableCell>
                    <TableCell>0</TableCell>
                    <TableCell>1</TableCell>
                    <TableCell>0</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Paracetamol Tbl. 500 mg</TableCell>
                    <TableCell>1</TableCell>
                    <TableCell>1</TableCell>
                    <TableCell>1</TableCell>
                    <TableCell>1</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Celecoxib Tbl. 200 mg</TableCell>
                    <TableCell>1</TableCell>
                    <TableCell>0</TableCell>
                    <TableCell>1</TableCell>
                    <TableCell>0</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Zolpidem Tbl. 10 mg</TableCell>
                    <TableCell>0</TableCell>
                    <TableCell>0</TableCell>
                    <TableCell>0</TableCell>
                    <TableCell>0.5</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Paraffin Sirup ml</TableCell>
                    <TableCell>0</TableCell>
                    <TableCell>20</TableCell>
                    <TableCell>0</TableCell>
                    <TableCell>0</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={5}></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Ringerfundin 1000ml / 24h i.v. Dauerinfusion</TableCell>
                    <TableCell>8 Uhr</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              </Table>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableHead colSpan={4}>Medikamente in Reserve:</TableHead>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Schmerz</TableCell>
                    <TableCell colSpan={3}>
                      Oxycodon Schmelztablette 5 mg p.o. max. 4-stdl.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell rowSpan={2}>Nausea</TableCell>
                    <TableCell>1) Metoclopramid Tbl. 10 mg max 3x1 / 24 h</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>2) Ondansetron Filmtbl. 4 mg max. 8-stdl.</TableCell>
                  </TableRow>
                </TableBody>
              </Table>

            </TableContainer>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Verordnungen an die Pflege</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer>
              <Table responsive striped bordered hover>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      Blutdruck / Puls / Sauerstoffsättigung (sO&#8322;) /
                      Atemfrequenz
                    </TableCell>
                    <TableCell>
                      1. postoperativer Tag 4x tgl.<br></br>2. postoperativer
                      Tag 3x tgl.<br></br>ab 3. postoperativer Tag 1x tgl.
                      <br></br>Limiten:<br></br>Blutdruck systolisch {">"}
                      180mmHg &rarr; Meldung an Arzt<br></br>Blutdruck
                      diastolisch {"<"}
                      90mmHg &rarr; 250ml Ringer Bolus und Meldung an den Arzt{" "}
                      <br></br>
                      Puls {"<"} 45/min &rarr; Meldung an den Arzt<br></br>
                      Puls {">"} 120 &rarr; Meldung an den Arzt<br></br>
                      sO&#8322; {"<"} 90% &rarr; 2-4Liter/min O&#8322;
                      nasalAtemfrequenz {"<"}10/min &rarr; Meldung an Arzt
                      <br></br>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Temperatur</TableCell>
                    <TableCell>
                      Analog zu Blutdruck / Puls<br></br>Limite: Temperatur{" "}
                      {">"}38.5°C &rarr; 2x2 Blutkulturen und Meldung an
                      Dienstarzt
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Essen / Trinken</TableCell>
                    <TableCell>Trinken ab sofort, Essen wenn keine Nausea</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Kost</TableCell>
                    <TableCell>Normal</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Mobilität</TableCell>
                    <TableCell>
                      Frei, sobald Sensorik und Motorik ohne Befund,
                      Vollbelastung mit Gehstöcken
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Labor</TableCell>
                    <TableCell>Morgen Blutentnahme: kleines Blutbild</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Kurve</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={2}></TableCell>
                    <TableCell colSpan={2}>Tag 1</TableCell>
                    <TableCell colSpan={2}>Tag 2</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}></TableCell>
                    <TableCell>abends</TableCell>
                    <TableCell>nachts</TableCell>
                    <TableCell>morgens</TableCell>
                    <TableCell>mittags</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>Größe: 172 cm</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>Gewicht (kg)</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>Temperatur (°C)</TableCell>
                    <TableCell>36.6</TableCell>
                    <TableCell>36.8</TableCell>
                    <TableCell>36.8</TableCell>
                    <TableCell>36.4</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>Blutdruck (mmHg)</TableCell>
                    <TableCell>128/84</TableCell>
                    <TableCell>132/68</TableCell>
                    <TableCell>140/80</TableCell>
                    <TableCell>144/80</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>Puls</TableCell>
                    <TableCell>68</TableCell>
                    <TableCell>80</TableCell>
                    <TableCell>70</TableCell>
                    <TableCell>68</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>Sauerstoffsättigung (sO&#8322;)</TableCell>
                    <TableCell>94%</TableCell>
                    <TableCell>97%</TableCell>
                    <TableCell>97%</TableCell>
                    <TableCell>95%</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>Stuhlgang</TableCell>
                    <TableCell></TableCell>
                    <TableCell>kein</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>Schmerzen</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>
                      Peripherer Venenkatheter linker Unterarm; Verbandswechsel:
                      alle 5 Tage oder bei Bedarf
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Pflegediagnose</Typography>
          </AccordionSummary>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell colSpan={2}>Diagnose</TableCell>
                <TableCell colSpan={2}>Merkmale</TableCell>
                <TableCell colSpan={2}>Beeinflussende Faktoren</TableCell>
                <TableCell colSpan={2}>Ziel</TableCell>
                <TableCell colSpan={2}>Maßnahmen</TableCell>
                <TableCell colSpan={2}>Evaluation</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>1</TableCell>
                <TableCell colSpan={2}>Beeinträchtigte Gehfähigkeit</TableCell>
                <TableCell colSpan={2}>
                  Beeinträchtigte Fähigkeit Treppen zu steigen,
                  erforderliche Strecken zu gehen
                </TableCell>
                <TableCell colSpan={2}>
                  <ul>
                    <li>Reduzierte Ausdauer</li>
                    <li>Umgebungsbedingte Hürde</li>
                    <li>ungenügende Muskelkraft</li>
                    <li>Schmerzen</li>
                  </ul>
                </TableCell>
                <TableCell colSpan={2}>
                  Patientin ist bis zum Austritt sicher und selbstständig
                  mit oder Gehstöcke mobil inkl. Treppensteigen
                </TableCell>
                <TableCell colSpan={2}>
                  <ul>
                    <li>Gehtraining in Begleitung morgens und abends</li>
                    <li>Tägliche Physiotherapie</li>
                  </ul>
                </TableCell>
                <TableCell colSpan={2}>Täglich</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>2</TableCell>
                <TableCell colSpan={2}>Selbstversorgungsdefizit Körperpflege</TableCell>
                <TableCell colSpan={2}>
                  Beeinträchtigte Fähigkeit, den Körper zu waschen und
                  abzuTableRowocknen
                </TableCell>
                <TableCell colSpan={2}>
                  <ul>
                    <li>umgebungsbezogenes Hindernis</li>
                    <li>Schwäche</li>
                    <li>Muskuloskeletale BeeinTableRowächtigungen</li>
                    <li>Schmerzen</li>
                  </ul>
                </TableCell>
                <TableCell colSpan={2}>
                  Führt Körperpflege unter Einbezug ihrer Ressourcen mit
                  Unterstützung der Pflegeperson durch
                </TableCell>
                <TableCell colSpan={2}>
                  <ul>
                    <li>Unterstützung bei Körperpflege und Ankleiden</li>
                  </ul>
                </TableCell>
                <TableCell colSpan={2}>Täglich</TableCell>
              </TableRow>
            </TableBody>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Pflegeverlauf</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Datum</TableCell>
                    <TableCell>Zeit</TableCell>
                    <TableCell colSpan={2}>Thema</TableCell>
                    <TableCell colSpan={6}>Pflegeverlauf</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell rowSpan={3}>Tag 1</TableCell>
                    <TableCell>07.30</TableCell>
                    <TableCell colSpan={2}>Eintritt</TableCell>
                    <TableCell colSpan={6}>
                      Frau Grausam tritt in Begleitung ihres Mannes auf der
                      Bettenstation ein für eine geplante Knie Totalprothese.
                      Eintrittsgepräch und präoperative Vorbereitungen werden
                      durchgeführt.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>08.10</TableCell>
                    <TableCell colSpan={2}>Prämedikation</TableCell>
                    <TableCell colSpan={6}>Frau G. wird prämediziert.</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>08.45</TableCell>
                    <TableCell colSpan={2}>Verlegung in Operationssaal</TableCell>
                    <TableCell colSpan={6}>
                      Frau G. wird in den Operationssaal verlegt.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={12}>
                      Operation und Aufenthalt Überwachungsstation
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell rowSpan={3}>Tag 1</TableCell>
                    <TableCell>15.00</TableCell>
                    <TableCell colSpan={2}>Verlegung aufs Zimmer</TableCell>
                    <TableCell colSpan={6}>
                      Frau Grausam wird aufs Zimmer verlegt. Patientin äußert
                      momentan keine Beschwerden.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>16.00</TableCell>
                    <TableCell colSpan={2}>Mobilisation</TableCell>
                    <TableCell colSpan={6}>
                      Patientin wird von der Physiotherapie mobilisiert. Frau G.
                      läuft mit Gehstöcken eine kurze STableRowecke im Zimmer.
                      Patientin konnte spontan eine große Portion Urin lösen.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>20.00</TableCell>
                    <TableCell colSpan={2}>Befinden</TableCell>
                    <TableCell colSpan={6}>
                      Patientin äußert sich momentan zufrieden. Wird ans Lavabo
                      mobilisiert für die Körperpflege.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell rowSpan={3}>Tag 2</TableCell>
                    <TableCell>02.00</TableCell>
                    <TableCell colSpan={2}>Schlaf</TableCell>
                    <TableCell colSpan={6}>Patientin scheint zu schlafen.</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>07.00</TableCell>
                    <TableCell colSpan={2}>Labor</TableCell>
                    <TableCell colSpan={6}>
                      Blutentnahme noch nicht durchgeführt. Liegt gerichtet im
                      Zimmer bereit
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>08.00</TableCell>
                    <TableCell colSpan={2}>Befinden</TableCell>
                    <TableCell colSpan={6}>
                      Frau Keller verspürt starke Übelkeit und muss sich
                      mehrfach übergeben. Äusser ebenfalls starke Schmerzen (VAS
                      8) . Reserve gegen Übelkeit und Schmerzen verabreicht.
                      Frau K. fühlt sich danach besser.
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onHide} color="primary">
          Schließen
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const configs = [
  {
    label: "Patientenakte",
    component: PatientFile,
  },
];

export default function PlaygroundAdditionalInfo(props) {
  const { config, ...rest } = props;
  const Component = configs[config].component;
  return <Component {...rest} />;
}
