import React, { useState } from 'react';
import {
  Container,
  Button,
  TextField,
  Checkbox,
  FormControlLabel
} from '@mui/material';

import logo from '../logo-simulern.png';
import { writeContactLead } from './firebase';
import Celebration from './Celebration';

function Contact() {
  const [showEmailField, setShowEmailField] = useState(false);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleCheckboxChange = (event) => {
    setShowEmailField(event.target.checked);
    setIsChecked(event.target.checked);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleBackClick = () => {
    window.history.back();
  };

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmission = (message, email) => {
    const contactLead = {
      message,
      email,
      timestamp: new Date().toISOString()
    };
    writeContactLead(contactLead).then(() => {
      setIsSubmitted(true);
    }).catch((error) => {
      alert(`Fehler beim Speichern der Nachricht: ${error}`);
    });
  };

  const isButtonDisabled = !message || !isChecked || (showEmailField && (!email || !isEmailValid(email)));

  if (isSubmitted) {
    return <Celebration />;
  }

  return (
    <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
      <Button variant="contained" onClick={handleBackClick} style={{ position: 'absolute', top: '20px', left: '20px', backgroundColor: "#002935" }}>
        Zurück
      </Button>

      <img src={logo} className="App-logo" alt="logo" height={50} style={{ position: 'absolute', top: '20px', right: '20px'}} />
   
      <TextField
        label="Gib hier deine Nachricht ein"
        variant="outlined"
        fullWidth
        margin="normal"
        multiline
        rows={6}
        value={message}
        onChange={handleMessageChange}
        style={{ marginTop: '100px' }}
      />
      <br />
      <FormControlLabel
        control={<Checkbox onChange={handleCheckboxChange} />}
        label="Ich erkläre mich hiermit einverstanden, dass SimuLern mich kontaktiert."
      />

      {showEmailField && (
        <TextField
          label="Gib hier deine E-Mail Adresse ein"
          variant="outlined"
          fullWidth
          margin="normal"
          value={email}
          onChange={handleEmailChange}
          style={{ marginTop: '2em' }}
        />
      )}
      <br />
      <Button
        variant="contained"
        color="primary"
        disabled={isButtonDisabled}
        onClick={() => handleSubmission(message, email)}
      >
        Abschicken
      </Button>
      <br />
      <br />
    </Container>
  );
}

export default Contact;