import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Avatar } from "@mui/material";

export default function TeamTable({ team, highlightUser = false }) {
  const getHighlightUserStyle = (player) => {
    if (player.uid === "1") {
      return { fontWeight: "bold" };
    } else {
      return {};
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Beruf</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {team && team?.players?.length > 0
            ? team.players.map((player, index) => (
              <TableRow
                key={index}
                style={highlightUser ? getHighlightUserStyle(player) : {}}
              >
                <TableCell>{player.first_name + " " + player.last_name}<Avatar src={player.profile_picture} sx={{ width: 30, height: 30 }} /></TableCell> 

                <TableCell>{player.occupation}</TableCell>
                
              </TableRow>
            ))
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
