import React, { useState, useEffect } from "react";
import "./Countdown.css"; // Hier werden die CSS-Stile definiert

// Countdown Komponente (gets rendered every second)
const Countdown = ({ end_at, setCountdownEnded, mode, showTimeWarning }) => {
  // Bring both dates into time zone central european time
  end_at = new Date(end_at);
  

  const calculateTimeLeft = () => {
    const difference = +new Date(end_at) - +new Date();

    if (difference > 0) {
      return {
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return null;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  // Die useEffect-Hook aktualisieren
  useEffect(() => {
    const timer = setTimeout(() => {
      const newTimeLeft = calculateTimeLeft();
      if (!newTimeLeft || end_at < new Date()) {
        setCountdownEnded(true);
       
        return;
      }
      
      if (newTimeLeft.minutes === 1 && newTimeLeft.seconds === 40) {
        showTimeWarning();
      }
      setTimeLeft(newTimeLeft);
    }, 1000);

    return () => clearTimeout(timer);
  });

  const countdownEnded = !timeLeft;

  const isLastMinute = timeLeft && timeLeft.minutes === 0;

  const className = countdownEnded
    ? "countdown-ended"
    : isLastMinute
    ? "countdown-last-minute"
    : "";

  return (
    <div>
      <div className={`countdown ${className}`}>
        {countdownEnded
          ? "Zeit abgelaufen"
          : `${String(timeLeft.minutes).padStart(2, "0")}:${String(
              timeLeft.seconds
            ).padStart(2, "0")} Minuten`}
      </div>
    </div>
  );
};

export default Countdown;
