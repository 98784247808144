import React, { useState, useEffect } from "react";
import "./LearnMore.css";
import { Button } from "@mui/material";
import logo from '../logo-simulern.png';
import team from '../team.png';

import {
    Container,
    Typography,
    IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SchoolIcon from "@mui/icons-material/School";
import AssessmentIcon from "@mui/icons-material/Assessment";
import GroupIcon from "@mui/icons-material/Group";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"; // Import Expand icon

const handleDemoClick = () => {
    window.location.href = "/demo";
}

const handleBackClick = () => {
    window.location.href = "/flyer";
}

const handleContactClick = () => {
    window.location.href = "/contact";
}



const testingButton = (
    <Button
        style={{ marginLeft: '10px', padding: '0 8px' }}
        variant="contained"
        onClick={handleDemoClick}
    >
        JETZT TESTEN
    </Button>
);

// Static left panel with icons, adjusting opacity based on expanded panel
const ProcessPanel = ({ expanded, handleExpand }) => (
    <div className="process-panel">
        <SchoolIcon
            className="process-icon"
            style={{ opacity: expanded === null || expanded === 'panel1' ? 1 : 0.3 }}
            onClick={() => handleExpand('panel1')}
        />
        <div className="process-line" />
        <GroupIcon
            className="process-icon"
            style={{ opacity: expanded === null || expanded === 'panel2' ? 1 : 0.3 }}
            onClick={() => handleExpand('panel2')}
        />
        <div className="process-line" />
        <AssessmentIcon
            className="process-icon"
            style={{ opacity: expanded === null || expanded === 'panel3' ? 1 : 0.3 }}
            onClick={() => handleExpand('panel3')}
        />
        <div className="process-line" />
        <AssignmentTurnedInIcon
            className="process-icon"
            style={{ opacity: expanded === null || expanded === 'panel4' ? 1 : 0.3 }}
            onClick={() => handleExpand('panel4')}
        />
    </div>
);

const SimuLernSteps = ({ expanded, setExpanded }) => {
    const handleExpand = (panel) => {
        setExpanded(panel === expanded ? null : panel);
    };

    return (
        <div className="steps-container">
            {expanded === null ? (
                <>
                    {["Vorbereitung", "Training", "Debriefing", "Nachbereitung"].map((title, index) => (
                        <div key={index} className="title" onClick={() => handleExpand(`panel${index + 1}`)}>
                            {title} {title === "Training" && testingButton}
                            <ExpandMoreIcon className={`expand-icon ${expanded === `panel${index + 1}` ? "expanded" : ""}`} />
                        </div>
                    ))}
                </>
            ) : (
                <div className="expanded-panel fade-in">
                    <IconButton onClick={() => setExpanded(null)} className="close-button">
                        <CloseIcon />
                    </IconButton>
                    {expanded === 'panel1' && (
                        <>
                            <Typography><b>Vorbereitung</b></Typography>
                            <Typography>
                                Beitreten und loslegen.<br /><br /> Für eine SimuLern-Session einfach
                                SimuLern-Link und Code an TeilnehmerInnen schicken und
                                Session starten.<br />
                                Automatische Einteilung in interprofessionelle Teams, geführtes
                                Onboarding und Kennenlernen alles dabei. Video und Ton 
                                sowieso.
                            </Typography>
                        </>
                    )}
                    {expanded === 'panel2' && (
                        <>
                            <Typography>
                                <b>Training</b>
                                {testingButton}
                            </Typography>
                            <Typography>
                                Virtuelles Lernerlebnis. <br /><br />
                                In der virtuellen Abbildung eines realen Szenarios durchlaufen die
                                TeilnehmerInnen in Teams eine Fehlersuche nach Risikopotentialen.<br />
                                Interprofessionelle Kommunikation und Zusammenarbeit unter Zeitdruck
                                sind dabei essentiell.<br />
                                Neugierig, das aus Perspektive der TeilnehmerInnen selbst
                                zu testen? <a href="#" onClick={handleDemoClick}>Hier</a> geht's zum Demo-Training.
                            </Typography>
                        </>
                    )}
                    {expanded === 'panel3' && (
                        <>
                            <Typography><b>Debriefing</b></Typography>
                            <Typography>
                                Strukturiertes, gemeinsames Debriefing einfach gemacht. <br /><br />SimuLern führt
                                in drei Phasen durch das Debriefing und unterstützt TrainerInnen 
                                mit einer automatischen Vor-Auswertung der Ergebnisse aus dem Training.<br />
                                So können sich alle auf das konzentrieren, was wirklich wichtig ist: die
                                gemeinsame Diskussion des Erlebten und relevante Learnings.
                            </Typography>
                        </>
                    )}
                    {expanded === 'panel4' && (
                        <>
                            <Typography><b>Nachbereitung</b></Typography>
                            <Typography>
                                Damit niemand was vergisst.<br /><br />
                                Noch während der SimuLern-Session können TrainerInnen mit einem Klick 
                                allen TeilnehmerInnen ein Zertifikat ausstellen und weitere Trainingsunterlagen 
                                zur Verfügung stellen.<br />
                                Die erfassten Trainings- und Debriefings-Ergebnisse stehen TrainerInnen 
                                auch noch nach dem Beenden der SimuLern-Session zur Verfügung.
                            </Typography>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};


const LearnMore = () => {
    const [expanded, setExpanded] = useState(null);

    const handleExpand = (panel) => {
        setExpanded(panel === expanded ? null : panel);
    }
    

    return (
        <div className="main-container">
            <Button variant="contained" onClick={handleBackClick} style={{ position: 'absolute', top: '20px', left: '20px', backgroundColor: "#002935" }}>
                Zurück
            </Button>

            <img src={logo} className="App-logo" alt="logo" height={50} style={{ position: 'absolute', top: '20px', right: '20px' }} />
            <div className="intro-text">
                <h3>Für ein geschultes Auge im Arbeitsalltag</h3>
                <p>
                    SimuLern vereint einfach nutzbares, virtuelles Training und Debriefing 
                    im Team für weniger Fehler und mehr Sicherheit im Arbeitsalltag. Alles
                    in einem digitalen Tool – und ganz ohne VR- oder AR-Brillen-Gedöns.
                </p>
            </div>

            <div className="content">
                <ProcessPanel expanded={expanded} handleExpand={handleExpand} />
                <SimuLernSteps expanded={expanded} setExpanded={setExpanded} />
            </div>

            <div className="footer">
                <p>Sie haben Fragen?</p>
                <Button variant="contained" onClick={handleContactClick} color="secondary">
                    Kontakt
                </Button>
                <Container style={{ textAlign: 'center', marginTop: '20px' }}>
                    <img src={team} alt="Cofounders" width={300} />
                    <p>Daniel Seger und Veronika Spielmann</p>
                    <b>
                        Gründer von SimuLern
                    </b>
                </Container>
            </div>
        </div>
    );
};

export default LearnMore;