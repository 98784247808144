// src/Celebration.js
import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logo from '../logo-simulern.png';
import './Celebration.css'; // Importieren Sie die CSS-Datei

const Celebration = () => {
  const navigate = useNavigate();

  const handleBackToHome = () => {
    navigate('/flyer');
  };

  return (
    <Container style={{ textAlign: 'center', marginTop: '70px' }}>
      <div className="checkmark-container">
        <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
          <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
          <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
        </svg>
      </div>
      <Typography variant="h4" gutterBottom>
        Vielen Dank für deine Nachricht!
      </Typography>
      <br />
      <Typography variant="body1" gutterBottom>
        Wir haben deine Nachricht erhalten und werden uns so schnell wie möglich bei dir melden.
      </Typography>
      <Button variant="contained" color="primary" onClick={handleBackToHome} style={{ marginTop: '20px' }}>
        Zurück zur Startseite
      </Button>
      <img src={logo} className="App-logo" alt="logo" height={150} style={{ marginTop: '50px' }} onClick={handleBackToHome} />
      <br />
    </Container>
  );
};

export default Celebration;