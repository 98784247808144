// NoteItem.js
import { useContext, useState } from "react";
import {
  List,
  ListItem,
  Button,
  TextField,
  Container,
  Card,
  Modal,
  Typography,
  IconButton,
  Grid,
} from "@mui/material";
import { Delete, Edit, ExpandLess, ExpandMore } from "@mui/icons-material";
import { UserContext } from "./UserContext";
import "./Notebook.css";

export function NoteItem({ id, title, note, deleteNote, editNote }) {
  const { user } = useContext(UserContext);
  const [editTitle, setEditTitle] = useState(null);

  const item = {
    _id: id,
    title: title,
    noteContent: note.noteContent,
    editedBy: note.editedBy,
  };

  return (
    <ListItem>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12}>
          <Typography variant="h6">{title}</Typography>
          <Typography variant="body2">
            Zuletzt bearbeitet von: {note.editedBy.first_name} {note.editedBy.last_name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {editTitle === title ? (
            <div>
              <TextField
                multiline
                rows={3}
                defaultValue={note.noteContent}
                onChange={(e) => (note.noteContent = e.target.value)}
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <Button
                onClick={() => {
                  editNote(item, note.noteContent, {
                    first_name: user ? user.first_name : "Maxi",
                    last_name: user ? user.last_name : "Mustermann (Sie)",
                    id: user ? user.uid : "1",
                    profile_picture: user ? user.profile_picture : "",
                  });
                  setEditTitle(null);
                }}
                variant="contained"
                color="primary"
                fullWidth
              >
                Speichern
              </Button>
            </div>
          ) : (
            <Typography variant="body1">{note.noteContent}</Typography>
          )}
        </Grid>
        <Grid item xs={12} container justifyContent="flex-end" style={{ borderBottom: "1px solid lightgrey" }}>
          <IconButton onClick={() => setEditTitle(title)} style={{ marginRight: "0.5rem" }}>
            <Edit />
          </IconButton>
          <IconButton onClick={() => deleteNote(item)}>
            <Delete />
          </IconButton>
        </Grid>
      </Grid>
   
    </ListItem>
  );
}
function EditableNoteContent({ notes, deleteNote, editNote }) {
  return (
    <div style={{ overflow: "auto" }}>
      <List style={{ maxHeight: "70vh", overflow: "auto", marginBottom: "0.5rem" }}>
        {Object.keys(notes).length > 0 ? (
          Object.entries(notes).map(([id, note], index) => {
            if (!note) {
              return null;
            }
            return (
              <NoteItem
                id={id}
                key={index}
                title={note.title}
                note={note}
                deleteNote={deleteNote}
                editNote={editNote}
              />
            );
          })
        ) : (
          <Container className="d-flex align-items-center justify-content-center mt-5">
            Ihr habt noch keine Notizen erstellt. Geht auf die Suche, die Zeit läuft!
          </Container>
        )}
      </List>
    </div>
  );
}

export default function Notebook({
  notes,
  deleteNote,
  editNote,
  type,
  show,
  onHide,
}) {
  const [isNotebookOpen, setIsNotebookOpen] = useState(false);

  const handleToggleClick = () => {
    setIsNotebookOpen(!isNotebookOpen);
  };

  if (type === "small") {
    return (
      <Modal open={show} onClose={onHide}>
        <div style={{ padding: "1rem", backgroundColor: "white", margin: "auto", marginTop: "10%" }}>
          <Typography variant="h6"><b>Notizbuch</b></Typography>
          <EditableNoteContent
            notes={notes}
            deleteNote={deleteNote}
            editNote={editNote}
          />
        </div>
      </Modal>
    );
  }

  return (
    <Card
      id="notebook"
      style={{
        backgroundColor: "lightgrey",
        color: "black",
        border: "1px solid black",
        marginBottom: "1rem",
      }}
      className={isNotebookOpen ? "notebook notebook-open" : "notebook notebook-closed"}
    >
      <div onClick={handleToggleClick} className="notebook-header" style={{ display: "flex", justifyContent: "space-between", padding: "1rem", cursor: "pointer" }}>
        <div style={{ flex: 1, textAlign: "left" }}>
          {Object.keys(notes).length} Notiz
          {Object.keys(notes).length !== 1 && "en"}
        </div>
        <div style={{ flex: 1, textAlign: "right" }}>
          {isNotebookOpen ? <ExpandLess /> : <ExpandMore />}
        </div>
      </div>
      <EditableNoteContent
        notes={notes}
        deleteNote={deleteNote}
        editNote={editNote} />
    </Card >
  );
}
