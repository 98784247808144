// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, addDoc, doc, getDoc } from "firebase/firestore";
import { toast } from "react-toastify";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyDkgBdmy--dAY7AzG_bM8yn0DrkS-hxc48",
//   authDomain: "simulern-leads-web.firebaseapp.com",
//   projectId: "simulern-leads-web",
//   storageBucket: "simulern-leads-web.appspot.com",
//   messagingSenderId: "727435229227",
//   appId: "1:727435229227:web:d44969639b64d71abad26e",
//   measurementId: "G-Z23DBDMNFH"
// };

const firebaseConfig = {
  apiKey: "AIzaSyAOHszMI6EGWOWq0fhAbBCmrTaMUTh_DU8",
  authDomain: "simulearn-801f6.firebaseapp.com",
  databaseURL: "https://simulearn-801f6-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "simulearn-801f6",
  storageBucket: "simulearn-801f6.appspot.com",
  messagingSenderId: "753025971358",
  appId: "1:753025971358:web:b49e7eb9ab3d2ad9d2b78f",
  measurementId: "G-PYJE26WR22"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

// Write a new entry to firestore in the collection "error_found_leads"
const writeErrorFoundLead = async (errorFoundLead) => {
  try {
    const docRef = await addDoc(collection(db, 'error_found_leads'), errorFoundLead);
    console.log('Document written with ID: ', docRef.id);
    return docRef.id;
  } catch (e) {
    console.error('Error adding document: ', e);
    return e;
  }
};

// Write a new entry to firestore in the collection "contact_leads"
const writeContactLead = async (contactLead) => {
  try {
    const docRef = await addDoc(collection(db, 'contact_leads'), contactLead);
    console.log('Document written with ID: ', docRef.id);
    return docRef.id;

  } catch (e) {
    console.error('Error adding document: ', e);
    return e;
  }
};

const readScenario = async (id) => {
  try {
    if (id === undefined || id === null || typeof id !== "string") {
      return null;
    }
    const scenarioRef = doc(db, "scenarios", id);
    const scenarioSnap = await getDoc(scenarioRef);
    const scenarioData = scenarioSnap.data();

    // Get the array of view references
    const viewsRefs = scenarioData.views;

    // Fetch the view data for each reference
    const viewsData = await Promise.all(
      viewsRefs.map(async (viewRef) => {
        try {
          const viewSnap = await getDoc(viewRef);
          if (!viewSnap.exists()) {
            return null;
          }
          // Return id and data
          return {
            _id: viewSnap.id,
            ...viewSnap.data(),
          };
        } catch (error) {
          return null;
        }
      })
    );

    // Add the view data to the scenario data
    scenarioData.views = viewsData;

    // Fetch the item data for each view
    const scenarioDataWithItems = await Promise.all(
      scenarioData.views.map(async (view) => {
        if (view.items === undefined || view.items.length === 0) {
          return view;
        }
        // Get the array of item references
        const itemsRefs = view.items;

        if (itemsRefs === undefined || itemsRefs.length === 0) {
          return view;
        }

        // Fetch the item data for each reference
        const itemsData = await Promise.all(
          itemsRefs.map(async (itemRef) => {
            try {
              const itemSnap = await getDoc(itemRef.path);
              if (!itemSnap.exists()) {
                return null;
              }
              return {
                _id: itemSnap.id,
                ...itemSnap.data(),
                position: itemRef.position,
                rotation: itemRef.rotation,
                scale: itemRef.scale,
              };
            } catch (error) {
              return null;
            }
          })
        );

        // Add the item data to the view data
        view.items = itemsData;

        return view;
      })
    );

    // Fetch the switch data for each view
    const scenarioDataWithSwitches = await Promise.all(
      scenarioDataWithItems.map(async (view) => {
        // Get the array of switch references
        const switchesRefs = view.switches;

        if (switchesRefs === undefined || switchesRefs.length === 0) {
          return view;
        }

        // Fetch the switch data for each reference
        const switchesData = await Promise.all(
          switchesRefs.map(async (switchRef) => {
            try {
              const switchSnap = await getDoc(switchRef);
              if (!switchSnap.exists()) {
                return null;
              }
              // Return id and data
              return {
                _id: switchSnap.id,
                ...switchSnap.data(),
              };
            } catch (error) {
              return null;
            }
          })
        );

        // Add the switch data to the view data
        view.switches = switchesData;

        return view;
      })
    );

    // Add the view data to the scenario data
    scenarioData.views = scenarioDataWithSwitches;

    return {
      _id: scenarioSnap.id,
      ...scenarioData,
    };
  } catch (error) {
    toast.error(error.message);
  }
};


export { writeErrorFoundLead, writeContactLead, readScenario };