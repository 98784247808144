import React, { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Container,
} from "@mui/material";
import { UserContext } from "./UserContext";

function PlaygroundItemModal({
  show,
  setShow,
  title,
  image,
  notes,
  addNote,
  mode,
  id,
}) {
  const { user } = useContext(UserContext);
  const [item, setItem] = useState(null);
  const [note, setNote] = useState("");
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (show) {
      setItem({ title: title, image: image, _id: id });
      const existingNote = notes[id]?.noteContent;
      setNote(existingNote ? existingNote : "");
    }
    setIsChanged(false);
  }, [show]);

  const handleClose = () => {
    setShow(false);
  };

  const handleAddNote = () => {
    let userData = user;
    if (mode !== "multiplayer") {
      userData = {
        first_name: "Maxi",
        last_name: "Muster",
        id: "1",
        profile_picture: "...",
      };
    }
    addNote(item, note, {
      first_name: userData.first_name,
      last_name: userData.last_name,
      id: userData.uid,
      profile_picture: userData.profile_picture,
    });
    handleClose();
  };

  const handleTextChange = (e) => {
    setNote(e.target.value);
    setIsChanged(true);
  };

  return (
    <Dialog open={show} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>{item && item.title}</DialogTitle>
      <DialogContent>
        {item && (
          <Container>
            <img
              src={item.image}
              alt={item.title}
              style={{ display: "block", margin: "auto", height: "50vh" }}
            />
            <TextField
              multiline
              rows={5}
              variant="outlined"
              fullWidth
              margin="normal"
              placeholder="Wenn Sie denken, dass es sich hierbei um eine Gefährdung handelt, beschreiben Sie diese bitte kurz."
              value={note}
              onChange={handleTextChange}
            />
          </Container>
        )}
      </DialogContent>
      <DialogActions>
        {isChanged && (
          <Button variant="contained" color="primary" onClick={handleAddNote}>
            Speichern
          </Button>
        )}
        <Button variant="contained" onClick={handleClose}>
          Schließen
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PlaygroundItemModal;
