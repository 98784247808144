import React, { useState } from "react";
import { Box } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

function Watermark() {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      style={{
        textAlign: "left",
        position: "fixed",
        bottom: "4rem",
        left: "5px",
        width: "20%",
        zIndex: 1,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        padding: "0.5rem",
        borderRadius: "0.5rem",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <p
        style={{
          color: "white",
          fontSize: "0.8rem",
          cursor: "pointer",
          margin: 0,
          ...(isHovered && { textDecoration: "underline" }),
        }}
        onClick={() => (window.location.href = "/contact")}
      >
        <Box
          sx={{
            position: "absolute",
            top: "5px",
            right: "5px",
            color: "white",
            cursor: "pointer"
          }}
        >
          <InfoIcon />
        </Box>
        Das hier sichtbare Szenario ist rein exemplarisch. An welches Szenario denken Sie? Wir können es für sie umsetzen.
      </p>
    </div>
  );
}

export default Watermark;
