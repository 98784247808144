import React from 'react';
import { Link } from 'react-router-dom';
import './Flyer.css';
import logo from '../logo-simulern.png';
import { Button } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { Image, Lightbulb } from '@mui/icons-material';
import error_image from './error_image.jpeg';

function Flyer() {
  return (
    <div className="Flyer">
      <br />
      <img src={logo} alt="logo" style={{ position: 'absolute', top: '20px', right: '20px', height: '50px' }} />
      <br />
      <h3>Herzlich Willkommen!</h3>
      <h4>
        SimuLern, für ein geschultes
        Auge im <br />Arbeitsalltag.
      </h4>
      <img src={error_image} alt="error_image" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
      <br />
      <br />
      <Link to="/flyer/found-error">
        <Button variant="contained" color="primary" startIcon={<Lightbulb />} className='big-action-button'>
          Ich habe einen Fehler gefunden!
        </Button>
      </Link>
      <br />
      <br />
      <Link to="/flyer/learn-more">
        <Button variant="outlined" color="primary" startIcon={<InfoIcon />} className='big-action-button'>
          Mehr erfahren
        </Button>
      </Link>
      <br />
      <br />
      <Link to="/contact">
        <Button variant="outlined" color="primary" startIcon={<ContactMailIcon />} className='big-action-button'>
          Kontakt aufnehmen
        </Button>
      </Link>
      <br />
      <br />
    </div>
  );
}

export default Flyer;