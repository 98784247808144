import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logo from './logo-simulern.png';

function App() {
    const navigate = useNavigate();

    const handleContactClick = () => {
        navigate('/contact');
    };

    const handleFlyerClick = () => {
        navigate('/flyer');
    }


    return (
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                textAlign: 'center',
            }}
        >
            <Box
                component="img"
                src={logo}
                alt="Logo"
                style={{ width: '200px', marginBottom: '20px' }}
            />
            <Typography variant="h6" gutterBottom style={{ marginBottom: '40px' }}>
                Diese Seite befindet sich noch im Aufbau
            </Typography>
            <Box sx={{ display: 'flex', gap: '10px' }}>
                <Button variant="contained" color="primary" onClick={handleContactClick}>
                    Kontakt
                </Button>
                <Button variant="contained" color="secondary" onClick={handleFlyerClick}>
                    Flyer
                </Button>
            </Box>
        </Container>
    );
}

export default App;