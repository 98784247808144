import { useState, useEffect } from 'react';

function RedirectCountdown({ initialCount, redirectTo }) {
  const [count, setCount] = useState(initialCount);

  useEffect(() => {
    if (count > 0) {
      const timerId = setTimeout(() => setCount(count - 1), 1000);
      return () => clearTimeout(timerId); 
    } else {
      window.location.href = redirectTo;
    }
  }, [count, redirectTo]);

  return <div>Weiterleitung in {count} Sekunden...</div>;
}

export default RedirectCountdown;
