import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Link } from '@mui/material';
import RedirectCountdown from './PlaygroundRedirectCountdown';

const handleClose = () => {
  window.location.href = "/flyer/learn-more";
};

function PlaygroundEndModal({ show }) {
  return (
    <Dialog open={show} onClose={handleClose} disableBackdropClick disableEscapeKeyDown>
      <DialogTitle>Zeit abgelaufen</DialogTitle>
      <DialogContent>
        <Typography>
          Die Zeit ist abgelaufen. Vielen Dank für Ihre Teilnahme!
        </Typography>
        <br />
        <Typography>
          Ihr Ergebnis wird von Ihren TrainerInnen ausgewertet und diese teilen die
          Ergebnisse mit Ihnen im anschließenden, gemeinsamen Debriefing.
        </Typography>
        <br />
        <Typography>
          Sie werden in wenigen Sekunden automatisch zur Startseite
          weitergeleitet. Falls Sie nicht automatisch weitergeleitet werden,
          klicken Sie bitte <Link href="/" style={{ color: "black" }}>hier</Link>.
        </Typography>
        <br />
        <RedirectCountdown initialCount={15} redirectTo="/" />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Schließen
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PlaygroundEndModal;
